import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import styled from 'styled-components';
import Logo from './svgs/website_logo.js';
import { Link as ScrollLink, animateScroll as scroll } from "react-scroll";

const HeaderStyled = styled.header`
  background: #413E4F;
  color: white;
  position: sticky;
  top: 0;
  z-index: 9999;

  a, button{
      background: transparent;
      border: 0;
      text-transform: uppercase;
      color: #fff;
      text-decoration: none;
      cursor: pointer;

      &:focus{
        border: 0;
        outline: none;
      }
      
    }
`

const HeaderContent = styled.div`
    margin: 0 auto;
    max-width: 960px;
    padding: 1rem;
    text-align: center;
    
    @media(min-width: 768px){
      display: flex;
      justify-content: space-between;
      padding: 2rem 4rem;
    }

    @media(min-width: 1024px){
      padding: 2rem 0;
    }
`
const HeaderLinks = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: center;

  li{
    margin-left: 1rem;

    &:first-child{
      margin: 0;
    }

    @media(min-width: 768px){
      margin-left: 2rem;
    }
  }
`

const scrollToTop = () => {
  scroll.scrollToTop();
};

const Header = ({ siteTitle }) => (
  <HeaderStyled>
    <HeaderContent>

      <button onClick={() => scrollToTop()}><Logo /></button>
      <HeaderLinks>
        <li><button onClick={() => scrollToTop()}>Home</button></li>
        <li><ScrollLink to="media" smooth="true" offset={-80}>Media</ScrollLink></li>
        <li><ScrollLink to="about" smooth="true" offset={-80}>About</ScrollLink></li>
        <li><ScrollLink to="gigs" smooth="true" offset={-80}>Gigs</ScrollLink></li>
        <li><ScrollLink to="contact" smooth="true" offset={-80}>Contact</ScrollLink></li>
      </HeaderLinks>
    </HeaderContent>
  </HeaderStyled>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
